<template>
  <p-container class="detail-container" :showBack="true">
    <template slot="toolbar">
      <a-button type="primary">提交</a-button>
    </template>

    <a-form > 
        <a-collapse :activeKey="[1,2,3]" :bordered="false">
          <a-collapse-panel key="1" header="基本信息">
            <a-row :gutter="24">   
              <a-col :span="24">
                <a-form-item label="专利名称">
                  <a-input v-model="type" :disabled="true"/>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="申请号">
                  <a-input v-model="id" :disabled="true"/>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="专利类型">
                  <a-select></a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="法律状态">
                  <a-select></a-select>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="申请人">
                  <a-input :disabled="true"/>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="发明人">
                  <a-input :disabled="true"/>
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="摘要">
                  <a-textarea :disabled="true"/>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="学科分类（一级）" :required="true">
                  <a-select></a-select>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="学科分类（二级）" :required="true">
                  <a-select></a-select>
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="学科分类（三级）" :required="true">
                  <a-select></a-select>
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="附属载体" :required="true">
                  <a-checkbox-group :options="plainOptions" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="设备分类（一级）" :required="true">
                  <a-select></a-select>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="设备分类（二级）" :required="true">
                  <a-select></a-select>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="应用阶段（成熟度）" :required="true">
                  <a-select></a-select>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="重要性" :required="true">
                  <a-select></a-select>
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="获奖情况">
                  <a-textarea />
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="获奖年份">
                  <a-select></a-select>
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="国内市场前景" :required="true">
                  <a-select></a-select>
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="国内市场前景说明">
                  <a-textarea />
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="国外市场前景" :required="true">
                  <a-select></a-select>
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="国外市场前景说明">
                  <a-textarea />
                </a-form-item>
              </a-col>
            </a-row>
          </a-collapse-panel>
          <a-collapse-panel key="2" header="项目信息">
          </a-collapse-panel>
          <a-collapse-panel key="3" header="产品信息">
            
          </a-collapse-panel>
        </a-collapse>

    </a-form>
  </p-container>
</template>

<script>
const plainOptions = ['设备', '纯理论方法', '软件', '其他'];
export default {
  data(){
    return {
      plainOptions,
      type: '',
      id: ''
    }
  },
  created(){
    this.type = this.$route.params.type;
    this.id = this.$route.params.id;
  },
  //修改列表页的meta值，false时再次进入页面会重新请求数据。
  beforeRouteEnter(to, from, next) {
      to.meta.notRefresh = true;
      next()
  }
}
</script>

<style>

</style>